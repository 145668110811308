/* Hide scrollbar for Chrome, Safari and Opera */
.historyContainer::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  .historyContainer {
    -ms-overflow-style: none;
  }
  .onHoverCoursorChanged:hover{
   color: aqua; 
   cursor: pointer;
  }